exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-studies-easybar-js": () => import("./../../../src/pages/case-studies/easybar.js" /* webpackChunkName: "component---src-pages-case-studies-easybar-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-studies-ushaz-js": () => import("./../../../src/pages/case-studies/ushaz.js" /* webpackChunkName: "component---src-pages-case-studies-ushaz-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dedicated-team-js": () => import("./../../../src/pages/dedicated-team.js" /* webpackChunkName: "component---src-pages-dedicated-team-js" */),
  "component---src-pages-fixed-price-js": () => import("./../../../src/pages/fixed-price.js" /* webpackChunkName: "component---src-pages-fixed-price-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

